/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../app/hooks";
import Select from "../../components/atoms/Select";
import {
  DimensionSelections,
  HeightSelection,
  ProductSelectionDetails,
  ProductShape,
} from "../../types/types";

type Props = {
  children?: ReactNode;
  onChange: (dimensions: ProductSelectionDetails) => void;
};

type ReducerAction = {
  type: string;
  payload: string | number;
};

const dimensionsReducer = (
  state: ProductSelectionDetails,
  action: ReducerAction
): ProductSelectionDetails => {
  switch (action.type) {
    case "grade":
      if (action.payload === "") {
        return initialState;
      }
      return {
        ...state,
        grade: action.payload as string,
        shape: "Unknown",
        width: 0,
        height: 0,
        wall: 0,
      };
    case "shape":
      return {
        ...state,
        shape: action.payload as ProductShape,
        width: 0,
        height: 0,
        wall: 0,
      };
    case "width":
      return {
        ...state,
        width: action.payload as number,
        height: 0,
        wall: 0,
      };
    case "height":
      return {
        ...state,
        height: action.payload as number,
        wall: 0,
      };

    case "wall":
      return {
        ...state,
        wall: action.payload as number,
      };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

const initialState: ProductSelectionDetails = {
  grade: "",
  height: 0,
  wall: 0,
  width: 0,
  shape: "Unknown",
};

export type DimensionsSelectRefType = {
  reset: () => void;
};

const DimensionsSelectComponent = forwardRef<DimensionsSelectRefType, Props>(
  ({ onChange }, ref) => {
    const [selectionState, selectionDispatch] = useReducer(
      dimensionsReducer,
      initialState
    );
    const productSelections = useAppSelector(
      (state) => state.product.productSelections
    );
    const grades =
      productSelections?.gradeSelections.map((grade) => grade) ?? [];
    const shapes =
      grades?.find((item) => item.grade === selectionState.grade)
        ?.shapeSelections ?? [];
    const widths =
      shapes?.find((item) => item.shape === selectionState.shape)
        ?.dimensionSelections ?? [];
    const heights =
      selectionState.shape === "Rectangle"
        ? (widths as HeightSelection[])?.find(
            (item: HeightSelection) => item.dimension === selectionState.width
          )?.dimensionSelections ?? []
        : [];
    const wallSelectionSource =
      selectionState.shape === "Rectangle"
        ? heights.find((item) => item.dimension === selectionState.height)
        : (widths as DimensionSelections[])?.find(
            (item) => item.dimension === selectionState.width
          );
    const walls = wallSelectionSource?.wallSelections ?? [];

    useEffect(() => {
      const compoundProductKey = walls.find(
        (item) => item.decimalWallThickness === selectionState.wall
      )?.productIndex?.compoundProductKey;
      onChange({
        ...selectionState,
        compoundProductKey,
      });
    }, [selectionState]);

    useImperativeHandle(ref, () => ({
      reset() {
        selectionDispatch({ type: "reset", payload: "" });
      },
    }));

    return (
      <div>
        <label css={tw`mx-2`}>Grade:</label>
        <Select
          name="grade"
          minWidth="14ch"
          disabled={grades.length === 0}
          value={selectionState.grade}
          onChange={(grade: string) =>
            selectionDispatch({ type: "grade", payload: grade })
          }
          data={[
            { value: "", label: "Select" },
            ...grades.map((grade) => {
              return { value: grade.grade, label: grade.grade };
            }),
          ]}
        />
        <label css={tw`mx-2`}>Shape:</label>
        <Select
          minWidth="13ch"
          disabled={shapes.length === 0}
          name="shape"
          value={selectionState.shape}
          onChange={(shape: ProductShape) =>
            selectionDispatch({ type: "shape", payload: shape })
          }
          data={[
            { value: "Unknown", label: "Select" },
            ...shapes.map((shape) => {
              return { value: shape.shape, label: shape.shape };
            }),
          ]}
        />
        <label css={tw`mx-2`}>Width:</label>
        <Select
          disabled={widths.length === 0}
          name="width"
          minWidth="14ch"
          value={selectionState.width}
          onChange={(width: number) =>
            selectionDispatch({ type: "width", payload: width })
          }
          data={[
            { value: 0, label: "Select" },
            ...widths.map((width) => ({
              value: width.dimension,
              label: width.dimensionLabel,
            })),
          ]}
        />
        {selectionState.shape === "Rectangle" && (
          <>
            <label css={tw`mx-2`}>Height:</label>
            <Select
              disabled={heights.length === 0}
              name="height"
              minWidth="10ch"
              value={selectionState.height}
              onChange={(height: number) =>
                selectionDispatch({ type: "height", payload: height })
              }
              data={[
                { value: 0, label: "Select" },
                ...heights.map((height) => ({
                  value: height.dimension,
                  label: height.dimension.toString(),
                })),
              ]}
            />
          </>
        )}
        <label css={tw`mx-2`}>Wall Thickness:</label>
        <Select
          disabled={walls.length === 0}
          name="wall"
          minWidth="12ch"
          value={selectionState.wall}
          onChange={(wall: number) =>
            selectionDispatch({ type: "wall", payload: wall })
          }
          data={[
            { value: 0, label: "Select" },
            ...walls.map((wall) => ({
              value: wall.decimalWallThickness,
              label: wall.wall,
            })),
          ]}
        />
      </div>
    );
  }
);

DimensionsSelectComponent.displayName = "DimensionsSelectComponent";

export default DimensionsSelectComponent;
