/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useAppDispatch } from "../../../../app/hooks";
import { numberFormatter } from "../../../../helpers/numberFormat";
import {
  setCurrentProductIndex,
  setProductDetailsFromHistory,
} from "../../../../store/product.reducer";
import {
  PrimeInventorySiteTotals,
  ProductIndex,
} from "../../../../types/types";

type Props = {
  children?: ReactNode;
  sites?: PrimeInventorySiteTotals[];
  itemNumber?: string;
  productIndex?: ProductIndex;
  isEven: boolean;
  lengths?: number[];
};

const AllStockListItem: React.FC<Props> = ({
  sites,
  productIndex,
  isEven,
  lengths,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      {sites?.map((site, index) => (
        <tr
          css={[
            !isEven &&  tw`bg-nucor-table-alternate-row-background`,
            isEven && tw`bg-white`,
            index != (sites.length - 1) && 
            css`
              & td {
                border-top: 0px !important;
                border-bottom: 0px !important;
              }
            `,
            index == (sites.length - 1) && 
            css`
              & td {
                border-top: 0px !important;
              }
            `,
          ]}
          key={index}
        >
          <td css={tw`w-[22%] text-left`}>
            {index === 0 && (
              <button
                css={[
                  tw`text-nucor-link hover:text-nucor-link-hover text-left focus:text-nucor-link-hover focus:outline-none`,
                ]}
                type="button"
                onClick={() => {
                  if (!productIndex) return;
                  dispatch(setCurrentProductIndex(productIndex));
                  dispatch(setProductDetailsFromHistory(productIndex));
                  navigate("/portal/stock/stocklist");
                }}
              >
                {productIndex?.name}
              </button>
            )}
          </td>
          <td css={tw`w-[7%]`}>{productIndex?.grade}</td>
          <td css={tw`w-[6%]`}>{site?.site}</td>
          {lengths?.sort().map((length) => (
            <React.Fragment key={length}>
              <td style={{ width: `${65 / (lengths.length * 2)}%` }} css={tw`text-right`}>
                {site?.lengthTotals[length.toString()].totalPieces === 0
                  ? ""
                  : numberFormatter(
                      site?.lengthTotals[length.toString()].totalPieces
                    )}
              </td>
              <td style={{ width: `${65 / (lengths.length * 2)}%` }} css={tw`text-right`}>
                {site?.lengthTotals[length.toString()].totalWeight === 0
                  ? ""
                  : numberFormatter(
                      site?.lengthTotals[length.toString()].totalWeight
                    )}
              </td>
            </React.Fragment>
          ))}
        </tr>
      ))}
    </>
  );
};

export default AllStockListItem;
