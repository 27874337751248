/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { ClaimsPagedResults } from "../../../../types/types";
import Pagination from "../../../../components/molecules/PaginationComponent";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { useAppDispatch } from "../../../../app/hooks";
import { updateClaimsPagination } from "../../../../store/document.reducer";
import ClaimDetails from "./ClaimDetails";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import TextButton from "../../../../components/atoms/TextButton";
import Loading from "../../../../components/atoms/Loading";

type Props = {
  results?: ClaimsPagedResults | null;
  children?: ReactNode;
  isLoading: boolean;
  onDetailShowingChange: (isShowing: boolean) => void;
};

const ClaimListResults: React.FC<Props> = ({ results, isLoading, onDetailShowingChange }) => {
  const dispatch = useAppDispatch();
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null);

  const handleBackClick = () => {
    setSelectedClaimId(null);
  };

  useEffect(() => {
    onDetailShowingChange(selectedClaimId !== null);
  }, [selectedClaimId])

  return (
    <div css={tw`relative`}>
      {isLoading && <Loading />}
      <div css={[selectedClaimId && tw`hidden`, tw`relative`] }>
        <Pagination
          isLoading={isLoading}
          items={results?.count ?? 0}
          onChange={(value) => {
            dispatch(updateClaimsPagination(value));
          }}
        />
        <table className="ntp-portal-table ntp-shrink-y-padding" css={tw`w-full text-xs`}>
          <thead>
            <tr css={tw`table-fixed`}>
              <th css={tw`w-[4%]`}>Id</th>
              <th css={tw`w-[8%]`}>Name</th>
              <th css={tw`w-[4%]`}>Site</th>
              <th css={tw`w-[8%]`}>Assigned To</th>
              <th css={tw`w-[4%]`}>Category</th>
              <th css={tw`w-[5%]`}>Process</th>
              <th css={tw`w-[5%]`}>Priority</th>
              <th css={tw`w-[4%]`}>Status</th>
              <th css={tw`w-[4%]`}>Resolution</th>
              <th css={tw`w-[6%]`}>Order</th>
              <th css={tw`w-[6%]`}>Invoice</th>
              <th css={tw`w-[5%]`}>Customer PO<br/>Number</th>
              <th css={tw`w-[5%]`}>RMA Number</th>
              <th css={tw`w-[4%]`}>Value</th>
              <th css={tw`w-[4%]`}>Opened<br/>Date</th>
              <th css={tw`w-[4%]`}>Closed<br/>Date</th>
            </tr>
          </thead>
          <tbody>
            {results?.list && results.list.length > 0 ? (
              results?.list.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <TextButton onClick={() => setSelectedClaimId(item.caseId)}>
                        {item.caseId}
                      </TextButton>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.department}</td>
                    <td>{Array.isArray(item.assignedTo) 
                        ? item.assignedTo.map((assigned: string, index) => <div key={index}>{assigned}</div>)
                        : item.assignedTo
                      }</td>
                    <td>{item.caseCategory}</td>
                    <td>{item.caseProcess}</td>
                    <td>{item.priority}</td>
                    <td>{item.status}</td>
                    <td>{item.caseResolution}</td>
                    <td>{item.salesOrder}</td>
                    <td>{Array.isArray(item.invoiceNumbers) 
                        ? item.invoiceNumbers.map((invoice: string, index) => <div key={index}>{invoice}</div>)
                        : item.invoiceNumbers
                      }</td>
                    <td>{item.salesOrderCustomerPO}</td>
                    <td>{item.rMANumber}</td>
                    <td css={tw`text-right`}>
                        {currencyFormatter(item.value)} {item.currency}
                    </td>
                    <td>{formatDateToShort(item.openedDate)}</td>
                    <td>{item.closedDate === "1900-01-01" ? "" : formatDateToShort(item.closedDate)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={16}>
                  No claims found for the selected criteria.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {selectedClaimId && <ClaimDetails claimId={selectedClaimId} onBackClick={handleBackClick} />}
    </div>
  );
};

export default ClaimListResults;
